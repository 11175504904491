import services from "./index";
import store from "@/store";
import { getAuth } from "firebase/auth";
import qs from "querystring";

export default {
    impersonationProfileId: null,
    getConfig() {
        return {
            headers: {
                //authorization: `Bearer ${store.getters.jwtToken}`,
                "x-impersonation-profile-id": this.impersonationProfileId || '',
                //"x-tenant-id": process.env.VUE_APP_TENANT,
            }
        };
    },
    getProfile(id) {
        return services.http.get(`/v1.0/admin/profile/${id}`, this.getConfig());
    },
    getTrainingPlan(id) {
        return services.http.get(`/v1.0/admin/training-plans/${id}`, this.getConfig());
    },
    async getCookie() {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            var token = await user.getIdToken();
            var request = { id_token: token };
            return services.http.post(`/v1.0/oauth/create-cookie`, request, this.getConfig());
        }
    },
    
}
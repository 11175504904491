<template>
  <div>
    <v-card-title class="headline">
      Admin Profile Viewer
      <v-spacer/>
      <v-text-field v-model="profileId" solo rounded clearable hide-details v-on:keyup.enter="search" @click:clear="resultSearch='';search()" @click:prepend="search" prepend-icon="fa-search" class="search-box" placeholder="Enter Profile Id" />
    </v-card-title>

    <v-alert v-if="error" type="error">{{error}}</v-alert>
    <v-alert v-if="response && (response.status == 404 || !profile)" type="warning">No profile found (or no permissions to view) with id '{{profileId}}'.</v-alert>
    <v-card v-if="profile">
      <v-card-title class="display-2">{{profile.name}}</v-card-title>
      <v-card-text class="mt-4">
        {{ profile.email || '- no email -' }}
        • joined: {{ profile.join_date | localDate('L LTS') }}
        • dob: {{ profile.dob | localDate('L') }}
        • gender: {{ profile.gender }}
        • id: {{ profile.id }}
        • uid: {{ data.firebase_uid }}
        <span v-if="profile.manual" class="font-weight-bold">* MANUAL CONNECT</span>
      </v-card-text>
      
      <div v-if="profile && profile.race_results && profile.race_results.length > 0">
        <MyRaceResultsOverview :results="profile.race_results"/>
      </div>

      <v-card-title class="subtitle">Activities</v-card-title>
      <v-card-text>
        View the details of last 25 activities.
      </v-card-text>
      <ActivityGrid :limit="50" :profile="profile" adminMode compact ref="activitiesGrid" :autoLoad="false" />

      <v-divider />

      <v-card-title class="subtitle">Connections</v-card-title>
      <v-card-text>
        <v-alert v-if="this.profile.providers && this.profile.providers.length > 0" type="info">Connected using <strong>{{profile.providers.join(' + ')}}</strong>.</v-alert>
        <v-alert v-else type="info">Not connected with external service.</v-alert>

        <p v-if="!profile.sync">No activity has been synced yet. Activities you sync with a connected app are automatically synced to your sodisp profile!</p>
        <v-alert v-else-if="profile.sync.status == 'OK'" type="success">Last activity was synced successfully at {{ profile.sync.timestamp | localDate("L LTS") }}.</v-alert>
        <v-alert v-else-if="profile.sync.status == 'INFO'" type="info">Last activity sync notification was at {{ profile.sync.timestamp | localDate("L LTS") }}: <strong>{{ profile.sync.msg }}</strong></v-alert>
        <v-alert v-else type="error">There was a sync issue during the last sync at {{ profile.sync.timestamp | localDate("L LTS") }}: <strong>{{ profile.sync.msg }}</strong></v-alert>

        <h4>Tokens</h4>
        <ul>
          <li v-for="(item, idx) in data.tokens" :key="idx">{{ item.provider }} (provider id: {{ item.id }})</li>
        </ul>
      </v-card-text>      

      <v-divider />

      <v-card-title class="subtitle">Joined events</v-card-title>
      <v-card-text v-if="!profile.events || profile.events == 0">
        <p class="mb-0">No joined events.</p>
      </v-card-text>      
      <EventGrid :items="profile.events" class="mx-4 mb-4"></EventGrid>
      <v-divider />

    </v-card>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import adminService from "@/services/adminService";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import EventGrid from "@/components/EventGrid.vue";
import ActivityGrid from '@/components/ActivityGrid.vue';
import ShareButton from '@/components/ShareButton.vue';
import MyRaceResultsOverview from '@/components/MyRaceResultsOverview.vue';

export default {
  name: "Profile",
  components: {
    EventGrid,
    ActivityGrid,
    ShareButton,
    MyRaceResultsOverview,
  },
  props: {
  },
  data() {
    return {
      profileId: null,
      response: null,
      profile: null,
      error: null,
      data: null,
      feedItems: [],
    };
  },
  async mounted() {
    this.profileId = this.$route.params.profileId;

    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {

    async search() {
      await this.getProfile();
    },
    async getProfile() {
      this.profile = null;
      this.response = null;
      if (this.user && this.profileId) {
        profileService.impersonationProfileId = this.profileId;
        adminService.impersonationProfileId = this.profileId;
        //store.commit('SET_IMPERSONATION_PROFILE_ID', this.profileId);
        try {
          //this.response = await profileService.get(/* full: */ true);
          this.response = await adminService.getProfile(this.profileId);
          this.data = this.response.data;
          this.profile = this.response.data.profile;
          await this.$nextTick();
          this.$refs.activitiesGrid.loadActivities(this.response.data.activities);
        }
        catch (ex) {
          console.error(ex);
          this.error = "Error getting profile information. Either no permissions or not existing profile."
        }
      }

      await adminService.getCookie();
    },
  },
  computed: {
    connectedWithGarmin() {
      return this.profile && this.profile.providers && this.profile.providers.indexOf('garmin') >= 0;
    },
    connectedWithStrava() {
      return this.profile && this.profile.providers && this.profile.providers.indexOf('strava') >= 0;
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">

.search-box {
  max-width: 350px;
  float: right;
}
</style>

